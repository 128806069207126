<template>
  <v-app-bar flat class="px-md-3 border-b" :elevation="4">
    <v-container class="d-flex py-0">
      <v-img src="https://www.assessment.com.tr/img/assessment-logo-black.png" max-width="160" class="" />
      <v-app-bar-title v-if="false">Logo</v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="d-flex" style="height: inherit">
        <ThemeSwitcher />
        <v-divider vertical style="height: 24px" class="mx-2 align-self-center" />
        <v-menu min-width="200px" rounded>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="text-subtitle-2" variant="text" style="height: inherit">
              <template #prepend>
                <v-avatar color="success" size="small">
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </template>
              <div class="text-left">
                {{ user.first_name }} {{ user.last_name }}
                <div class="text-disabled font-weight-regular">Hesabım</div>
              </div>
            </v-btn>
          </template>
          <v-list rounded="lg" :elevation="5" border slim density="comfortable">
            <v-list-item v-if="false" title="Hesabım">
              <template #prepend>
                <v-icon size="20">heroicons:user</v-icon>
              </template>
            </v-list-item>
            <v-list-item title="Çıkış Yap" @click="logout">
              <template #prepend>
                <v-icon size="20">heroicons:arrow-left-end-on-rectangle</v-icon>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-app-bar>

  <v-main>
    <NuxtPage v-bind="$attrs" />
  </v-main>

  <v-footer color="transparent">
    <v-container class="text-subtitle-2 font-weight-regular text-disabled text-center">
      Assessment © 2025
    </v-container>
  </v-footer>
</template>

<script setup>
import ThemeSwitcher from "@/components/common/ThemeSwitcher.vue";

const { logout } = useSanctumAuth();
const user = useSanctumUser();
</script>

<style></style>
